.jambPic{
    background-image: url(/public/hotelPic.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:bottom;
    height: 55vh;
    animation: mymove3 1.5s;
}
.jambPic2{
    background-image: url(/public/small-1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
    height: 55vh;
    animation: mymove3 1.5s;
}
.jambPic3{
    background-image: url(/public/Small-2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
    height: 55vh;
    animation: mymove3 1.5s;
}.jambPic4{
    background-image: url(/public/Small-5.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:bottom;
    height: 55vh;
    animation: mymove3 1.5s;
}
.jambPic5{
    background-image: url(/public/Small-4.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
    height: 55vh;
    animation: mymove3 1.5s;
}
@keyframes mymove3 {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.logo{
    margin-top: 1.5rem;
    object-fit: cover;
    height: 65px;
    margin-left: 1rem;
}
.gradient{
    background-image: linear-gradient(
        270deg,
        rgb(23,44,63,0.02) 0%,
        rgba(174, 120, 214, 0.6) 49%,
        rgb(107, 62, 184) 100%
      );
width: 40vw;
height: 55vh;
color: white;
}
.radius{
    margin-top: 1rem;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1.5rem;
    transition: scale 0.4s;
}
.radius:hover{
    scale: 1.3;
    transition-timing-function: ease-out;
    transition: scale 0.2s, transform 0.6s 0.2s;
}
.formInput{
    position: relative;
    background-color: white;
    width: 100%;
    bottom: 70px;
    height: 26vh;
    border-radius: 20px;
    border: 0.2px solid rgb(189, 185, 185);
    box-shadow: gray 0px 0px 10px 0px;
}
.giveWidth{
    margin-top: 1.5rem;
    width: 280px;
    margin-left: auto;
    margin-right: auto;
}
.smallHeader{
    font-size: 20px;
    font-weight: bold;
}

@media only screen and (max-width:1264px) {
    .formInput{
        height: 30vh;
    }
}
@media only screen and (max-width:834px) {
    .formInput{
        bottom: 10px;
    }
    .disappearIt{
        display: none;
    }
}
@media only screen and (max-width:577px) {
    .formInput{
        height: 35vh;
    }
    .smallPicDisapear{
        display: none;
    }
}
@media only screen and (max-width:496px) {
    .formInput{
        height: 36vh;
    }
}
@media only screen and (max-width:424px) {
    .gradient{  
          min-width: 60vw;
    }
}
@media only screen and (max-width:409px) {
    .giveWidth{
        width: 150px;
    }
}
@media only screen and (max-width:360px) {
    .logo{  
          height: 50px;
    }
}
@media only screen and (max-width:317px) {
    .logo{  
          height: 40px;
    }
}
