.jamHotelPic{
    background-image: url('https://images.unsplash.com/photo-1517840901100-8179e982acb7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x:39%;
    background-position-y:50% ;
    height: 55vh;
    animation: mymove3 1.5s;
}
.formInputHotels{
    color: rgb(0, 0, 0,0.6);
    position: sticky;
    z-index: 1;
    margin-top: -87px;
    background-color: rgb(255, 255, 255);
    top: 20px;
    width: 76%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    min-height: 15vh;
    border-radius: 20px;
    border: 0.2px solid rgb(189, 185, 185);
    box-shadow: gray 0px 0px 10px 0px;
    z-index: 2;
    animation: mymove3 1.5s;
}