.whatsappIcon:hover{
    transform: scale(1.2);
    transition: 0.5s;
    color: black;
}
.whatsappIcon{
    font-size:40px;
    color:#128C7E
}

.signupContainer{
    background-color: white;
    border-radius: 15px;
    width: 70%;
    animation: mymove3 1.5s;
}
@keyframes mymove3 {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.loginContainer{
    background-color: white;
    border-radius: 15px;
    width: 70%;
    animation: mymove2 1.5s;
}
@keyframes mymove2 {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.signBtn:active{
  box-shadow: 2px 2px 5px #fc894d;
}


/*  loading */
#load {
    position:absolute;
    width:600px;
    left:50%;
    top:40%;
    margin-left:-300px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:default;
  }
  
  #load div {
    position:absolute;
    width:20px;
    height:36px;
    opacity:0;
    font-family:Helvetica, Arial, sans-serif;
    animation:move 2s linear infinite;
    -o-animation:move 2s linear infinite;
    -moz-animation:move 2s linear infinite;
    -webkit-animation:move 2s linear infinite;
    transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    color:#35C4F0;
  }
  
  #load div:nth-child(2) {
    animation-delay:0.2s;
    -o-animation-delay:0.2s;
    -moz-animation-delay:0.2s;
    -webkit-animation-delay:0.2s;
  }
  #load div:nth-child(3) {
    animation-delay:0.4s;
    -o-animation-delay:0.4s;
    -webkit-animation-delay:0.4s;
    -webkit-animation-delay:0.4s;
  }
  #load div:nth-child(4) {
    animation-delay:0.6s;
    -o-animation-delay:0.6s;
    -moz-animation-delay:0.6s;
    -webkit-animation-delay:0.6s;
  }
  #load div:nth-child(5) {
    animation-delay:0.8s;
    -o-animation-delay:0.8s;
    -moz-animation-delay:0.8s;
    -webkit-animation-delay:0.8s;
  }
  #load div:nth-child(6) {
    animation-delay:1s;
    -o-animation-delay:1s;
    -moz-animation-delay:1s;
    -webkit-animation-delay:1s;
  }
  #load div:nth-child(7) {
    animation-delay:1.2s;
    -o-animation-delay:1.2s;
    -moz-animation-delay:1.2s;
    -webkit-animation-delay:1.2s;
  }
  
  @keyframes move {
    0% {
      left:0;
      opacity:0;
    }
    35% {
      left: 41%; 
      -moz-transform:rotate(0deg);
      -webkit-transform:rotate(0deg);
      -o-transform:rotate(0deg);
      transform:rotate(0deg);
      opacity:1;
    }
    65% {
      left:59%; 
      -moz-transform:rotate(0deg); 
      -webkit-transform:rotate(0deg); 
      -o-transform:rotate(0deg);
      transform:rotate(0deg); 
      opacity:1;
    }
    100% {
      left:100%; 
      -moz-transform:rotate(-180deg); 
      -webkit-transform:rotate(-180deg); 
      -o-transform:rotate(-180deg); 
      transform:rotate(-180deg);
      opacity:0;
    }
  }
  
  @-moz-keyframes move {
    0% {
      left:0; 
      opacity:0;
    }
    35% {
      left:41%; 
      -moz-transform:rotate(0deg); 
      transform:rotate(0deg);
      opacity:1;
    }
    65% {
      left:59%; 
      -moz-transform:rotate(0deg); 
      transform:rotate(0deg);
      opacity:1;
    }
    100% {
      left:100%; 
      -moz-transform:rotate(-180deg); 
      transform:rotate(-180deg);
      opacity:0;
    }
  }
  
  @-webkit-keyframes move {
    0% {
      left:0; 
      opacity:0;
    }
    35% {
      left:41%; 
      -webkit-transform:rotate(0deg); 
      transform:rotate(0deg); 
      opacity:1;
    }
    65% {
      left:59%; 
      -webkit-transform:rotate(0deg); 
      transform:rotate(0deg); 
      opacity:1;
    }
    100% {
      left:100%;
      -webkit-transform:rotate(-180deg); 
      transform:rotate(-180deg); 
      opacity:0;
    }
  }
  
  @-o-keyframes move {
    0% {
      left:0; 
      opacity:0;
    }
    35% {
      left:41%; 
      -o-transform:rotate(0deg); 
      transform:rotate(0deg); 
      opacity:1;
    }
    65% {
      left:59%; 
      -o-transform:rotate(0deg); 
      transform:rotate(0deg); 
      opacity:1;
    }
    100% {
      left:100%; 
      -o-transform:rotate(-180deg); 
      transform:rotate(-180deg); 
      opacity:0;
    }
  }
  @media screen and (max-width: 1000px) {
    .theLogo{
        display: none;
    }
  }
  @media screen and (max-width: 641px) {
    #load{
        width: 500px;
    }
  }
  @media only screen and (max-width:600px) {
    .signupContainer{  
          width: 100%;
    }
    .loginContainer{
      width: 100%;
    }
}
  @media screen and (max-width: 445px) {
    #load{
        width: 450px;
        left: 50%;
    }
  }
  @media screen and (max-width: 342px) {
    #load{
        width: 400px;
        left: 60%;
    }
  }
  @media screen and (max-width: 303px) {
    #load{
        width: 390px;
        left: 60%;
    }
  }
  @media screen and (max-width: 258px) {
    #load{
        width: 380px;
        left: 60%;
    }
  }