@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.firstPic{
    background-image: url('../toa-heftiba-N782gGjoko0-unsplash.jpg');
    min-height: 60vh;
    background-clip: padding-box;
    background-position-y: 80%;
    background-position-x: 100px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px 20px 20px 0px;
}
.firstPicSide{
    background-color: #9A5E37;
    min-height: 60vh;
    border-radius: 20px 0px 0px 20px;
        padding-right: 5rem;
        padding-left: 5rem;
}
.secondPic{
    background-image: url('../fernando-gutierrez-_eZ5UCr9_QM-unsplash.jpg');
    min-height: 60vh;
    background-position-y:-100%;
    background-clip: padding-box;
    background-attachment: fixed;
    background-repeat: no-repeat;
    border-radius: 20px 0px 0px 20px;
    background-size: cover;
}
.secondPicSide{
    background-color: #b27caf;
    min-height: 60vh;
    border-radius: 0px 20px 20px 0px;
    padding-right: 5rem;
    padding-left: 5rem;
}
.middleLeft{
    background-color: #7D3C1F;
    min-height: 95vh;
    border-radius: 20px 0px 0px 20px;
}
.aboutFont{
    font-family: 'Pacifico', cursive;
    font-size: 100px;
    transform: rotate(-90deg);
}
.flashFont{
    font-family: 'Pacifico',cursive;
    font-size: 100px;
}
.textCont{
    padding-right: 5rem;
    padding-left: 5rem;
    min-height: 95vh;
}
.aboutText{
    font-size: 15.5px;
}
.middleRight{
    background-color: #002927;
    min-height: 95vh;
    border-radius: 0px 20px 20px 0px;
}

@media screen and (max-width:992px) {
    .firstPic{
        border-radius:0;
        background-position-x: unset;
    }
    .firstPicSide{
        border-radius:0;
    }
    .textCont{
        padding-top: 2rem;
        min-height: 95vh;
    }
    .middleLeft{
        background-color: #7D3C1F;
        min-height: 90vh;
        border-radius: 0px;
    }
    .aboutFont{
        font-family: 'Pacifico', cursive;
        font-size: 100px;
        transform: rotate(0deg);
    }
    .flashFont{
        font-family: 'Pacifico', cursive;
        font-size: 100px;
    }
    .middleRight{
        background-color: #002927;
        min-height: 90vh;
        border-radius:0px;
    }
    .secondPic{
        background-image: url('../fernando-gutierrez-_eZ5UCr9_QM-unsplash.jpg');
        min-height: 60vh;
        background-position-y:-100%;
        background-clip: padding-box;
        background-attachment: fixed;
        background-repeat: no-repeat;
        border-radius:0px;
        background-size: cover;
    }
    .secondPicSide{
        background-color: #b27caf;
        min-height: 60vh;
        border-radius: 0px;
        padding-right: 5rem;
        padding-left: 5rem;
    }
  }
  @media screen and (max-width:450px){
    .firstPicSide{
        border-radius:0;
        padding-right: 2rem;
        padding-left: 2rem;
    }
    .textCont{
        padding-right: 2rem;
        padding-left: 2rem;
        min-height: 95vh;
    }
    .secondPicSide{
        background-color: #b27caf;
        min-height: 60vh;
        border-radius: 0px;
        padding-right: 2rem;
        padding-left: 2rem;
    }
    .aboutFont{
        font-family: 'Pacifico', cursive;
        font-size: 70px;
        transform: rotate(0deg);
    }
    .flashFont{
        font-family: 'Pacifico', cursive;
        font-size: 70px;
    }
    .middleLeft{
        background-color: #7D3C1F;
        min-height: 60vh;
        border-radius: 0px;
    }
    .middleRight{
        background-color: #002927;
        min-height: 60vh;
        border-radius:0px;
    }
  }