.textContainer{
    background-color: rgb(255, 255, 255,0.5);
    border-radius: 15px;
    padding-bottom: 0.1rem;
    margin-bottom: 1rem;
}

.box-1{
  border-radius:10px;
}
.btn-one {
  color: #FFF;
  transition: all 0.3s;
  position: relative;
  border-radius:10px;
}
.btn-one span {
  border-radius:10px;
  transition: all 0.3s;
}
.btn-one::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: black;
  border-bottom-color: black;
  transform: scale(0.1, 1);
}
.btn-one:hover span {
  letter-spacing: 2px;
}
.btn-one:hover::before {
  opacity: 1; 
  transform: scale(1, 1); 
}
.btn-one::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: #efc1cd97;
}
.btn-one:hover::after {
  opacity: 0; 
  transform: scale(0.1, 1);
}